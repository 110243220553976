<template>
  <el-dialog draggable overflow
             v-model="combinationDialog"
             title="关联组合课程"
             width="1520px"
             align-center>
          <div style="width: 100%;">
            <div style="display:flex;">
              <div class="item-card-type">
                <div style="font-size: 15px;font-weight: bold">主卡</div>
                <div style="height: 200px;text-align: left;margin-top: 10px">
                  <div v-if="gift.card != null">
                    <div @click="choiceGiftDialog = true,gift.index = null" >课程名称：{{gift.card.name}}</div>
                  </div>
                  <el-empty image-size="90" @click="choiceGiftDialog = true,gift.index = null" v-else description="点击选择课程" />
                </div>
              </div>
              <div class="item-card-type">
                <div style="font-size: 15px;font-weight: bold">副卡</div>
                <div style="height: 200px;text-align: left;margin-top: 10px">
                  <div v-if="gift.cardGiftList[0].productId != null">
                    <div  @click="choiceGiftDialog = true,gift.index = 0">课程名称：{{gift.cardGiftList[0].name}}</div>
                    <div style="display: flex;justify-content: space-between;text-align: left">
                      <div>
                        系数：<el-input-number v-model="gift.cardGiftList[0].coefficient" size="small" :min="0" :step="0.5" :max="100"/>
                      </div>
                      <div>
                        可额外赠送数量：<el-input-number v-model="gift.cardGiftList[0].promotionGiftNumber" size="small" :min="0" :step="0.5" :max="100"/>
                      </div>
                    </div>
                  </div>
                  <el-empty image-size="90" @click="choiceGiftDialog = true,gift.index = 0" v-else description="点击选择课程" />
                </div>
              </div>
            </div>
            <div style="display:flex;">
              <div class="item-card-type">
                <div style="font-size: 15px;font-weight: bold">298或398</div>
                <div style="height: 200px;text-align: left;margin-top: 10px">
                  <div v-if="gift.cardGiftList[1].productId != null">
                    <div  @click="choiceGiftDialog = true,gift.index = 1">课程名称：{{gift.cardGiftList[1].name}}</div>
                    <div style="display: flex;justify-content: space-between;text-align: left">
                      <div>
                        系数：<el-input-number v-model="gift.cardGiftList[1].coefficient" size="small" :min="0" :step="0.5" :max="100"/>
                      </div>
                      <div>
                        可额外赠送数量：<el-input-number v-model="gift.cardGiftList[1].promotionGiftNumber" size="small" :min="0" :step="0.5" :max="100"/>
                      </div>
                    </div>
                  </div>
                  <el-empty image-size="90" @click="choiceGiftDialog = true,gift.index = 1" v-else description="点击选择课程" />
                </div>
              </div>
              <div class="item-card-type">
                <div style="font-size: 15px;font-weight: bold">298或398的副卡</div>
                <div style="height: 200px;text-align: left;margin-top: 10px">
                  <div v-if="gift.cardGiftList[2].productId != null">
                    <div  @click="choiceGiftDialog = true,gift.index = 2">课程名称：{{gift.cardGiftList[2].name}}</div>

                    <div style="display: flex;justify-content: space-between;text-align: left">
                      <div>
                        系数：<el-input-number v-model="gift.cardGiftList[2].coefficient" size="small" :min="0" :step="0.5" :max="100"/>
                      </div>
                      <div>
                        可额外赠送数量：<el-input-number v-model="gift.cardGiftList[2].promotionGiftNumber" size="small" :min="0" :step="0.5" :max="100"/>
                      </div>
                    </div>
                  </div>
                  <el-empty image-size="90" @click="choiceGiftDialog = true,gift.index = 2" v-else description="点击选择课程" />
                </div>
              </div>
            </div>
          </div>
    <el-dialog v-model="choiceGiftDialog" title="选择课程" width="1200" draggable>
      <div>
        <el-form label-width="70px" class="demo-ruleForm" style="display:flex">
          <el-row>
            <el-col :span="6">
              <el-input size="small" v-model="searchCard.name" placeholder="查询卡片" style="width:120px"></el-input>
            </el-col>
            <el-col :span="6">
              <el-select size="small" v-model="searchCard.authority" placeholder="可视权限" class="handle-select mr10"
                         style="width:120px;">
                <el-option v-for="(item, index) in choice.authorityList " :key="index"
                           :label="item.dictLabel"
                           :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-select size="small" v-model="searchCard.subtractCourseWay" placeholder="消课方式"
                         class="handle-select mr10" style="width:120px;">
                <el-option v-for="(item,index) in choice.subtractCourseWayList " :key="index"
                           :label="item.dictLabel"
                           :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-select size="small" v-model="searchCard.setDateWay" placeholder="日期设置" class="handle-select mr10"
                         style="width:120px;">
                <el-option v-for="(item,index) in choice.setDateWayList " :key="index"
                           :label="item.dictLabel"
                           :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-button size="small" style="display:inline;margin-left: 20px;" @click="searchCardList">查询</el-button>
        </el-form>
        <el-table
            style="margin-top: 10px"
            height="440px"
            :row-style="{height:2+'px'}"
            :cell-style="{color:'#909399',fontSize:'13px',padding:'7px 0px'}"
            :data="courseCardTable" border>
          <el-table-column align="center" show-overflow-tooltip="true" prop="name" label="卡片名称"/>
          <el-table-column align="center" show-overflow-tooltip="true" prop="subtractCourseWayName" label="消课方式">
          </el-table-column>
          <el-table-column align="center" show-overflow-tooltip="true" label="日期设置">
            <template #default="scope">
              <el-tooltip class="box-item" effect="dark" placement="top">
                <template #content>
                  <div v-if="scope.row.setDateWay == 1">开始日期：{{ scope.row.startDate }}
                    <br/>结束日期：{{ scope.row.endDate }}
                  </div>
                  <div v-if="scope.row.setDateWay == 2">使用后{{ scope.row.howManyDay }}天内有效</div>
                  <div v-if="scope.row.setDateWay == 3">购买后{{ scope.row.howManyDay }}天内有效</div>
                  <div v-if="scope.row.setDateWay == 4">{{ scope.row.startDate }} 至 {{ scope.row.endDate }}
                    期间内可用{{ scope.row.howManyDay }}天
                  </div>
                  <div v-if="scope.row.setDateWay == 5">使用后{{ scope.row.endDate }}之前有效</div>
                  <div v-if="scope.row.setDateWay == 6">使用后{{ scope.row.howMonth }}个月内有效</div>
                </template>
                <el-tag circle>{{ scope.row.setDateWayName }}</el-tag>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column align="center" show-overflow-tooltip="true" prop="authorityName" label="可视权限">
          </el-table-column>
          <el-table-column align="center" show-overflow-tooltip="true" label="价格">
            <template #default="scope">{{ scope.row.money }} 元</template>
          </el-table-column>
          <el-table-column label="课程数量" align="center" show-overflow-tooltip="true">
            <template #default="scope">
              <div>
                {{ scope.row.subtractCourseWay != 1 ? '期卡' : scope.row.courseNumber + '次' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="80px">
            <template #default="scope">
              <el-switch
                  disabled
                  v-model="scope.row.state"
                  active-value="开启"
                  inactive-value="禁用"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="80px">
            <template #default="scope">
              <el-button size="small" type="text" @click="confirm(scope.row)">添加
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="outerVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCardGift">确 定</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {searchCard,addCardGift} from '@/api/card'
export default {
  name: "Combination",
  props: {
    choice: Object
  },
  data() {
    return {
      searchCard: { roleId: null },
      courseCardTable:[],
      gift:{
        cardGiftList:[
          {isNewOrders:1},
          {isNewOrders:2},
          {isNewOrders:3},
        ],
        card:null,
        cardId:null,
        mainId:null,
        index:null
      },
      choiceGiftDialog:false,
      combinationDialog:false,
    }
  },
  methods: {
    openDialog(card) {
      console.log(card,'card!!!')
      this.searchCard.areaId = card.areaId;
      this.searchCard.areaType = card.areaType;
      console.log(this.searchCard)
      this.gift.cardId = card.id;
      this.combinationDialog = true;
    },
    //确定赠品
    confirm(row) {
      if (this.gift.index == null){
        this.gift.card = row;
      }else {
        let item = this.gift.cardGiftList[this.gift.index];
        item.name = row.name
        item.coefficient = 0
        item.productId = row.id
        item.promotionGiftNumber = 0
        this.gift.cardGiftList[this.gift.index] = item;
      }
    },
    addCardGift(){
      console.log(this.gift)
      for (const item of this.gift.cardGiftList) {
        item.ruleId = this.gift.card.id
      }
      addCardGift(this.gift).then(val => {
        if (val.code == 200) {
          this.combinationDialog = false;
          this.$message.success("添加成功");
        } else {
          this.$message.error("添加失败！");
        }
      })
    },
    //查询卡片
    searchCardList() {
      searchCard(this.searchCard).then(res => {
        if (res.code == 200) {
          this.courseCardTable = res.data
        } else {
          this.$message.error('查询失败')
        }
      })
    },
  },
  created() {
    console.log(this.choice)
  }
}
</script>

<style scoped>
.item-card-type{
  width: 50%;
  box-shadow: 0 0 10px rgba(59, 59, 59, 0.1);
  margin: 10px;
  border-radius: 10px;
  padding:14px 20px;
  text-align: center;
}
</style>

